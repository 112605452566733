import React from "react";
import { Link } from "react-router-dom";
import { Statistics } from "../common/Statistics";
import { ScientistCorner } from "../common/ScientistCorner";
import { Footer } from "../common/Footer";
import { useTranslation } from "react-i18next";
import "./Frontpage.scss";

const registerImg = "/img/register.jpg";
const dataImg = "/img/data.jpg";

const Frontpage = (): JSX.Element => {
	const { t } = useTranslation();

	return (
		<div className="frontpage">
			<div className="container-fluid">
				<div className="row">
					<div className="col-12">
						<div className="row">
							<header className="col-12 text-center">
								<h1>{t("appName")}</h1>
								<h2>{t("frontPage:header")}</h2>
								<p>{t("frontPage:headerText")}</p>
							</header>
						</div>
						<div className="d-flex justify-content-center flex-column flex-md-row text-center to-system">
							<Link to="/registrerFunn" className="mb-4">
								<div className="row align-items-center no-gutters register-finds">
									<div className="col-6">
										<img src={registerImg} alt="" />
									</div>
									<div className="col-6 p-3">
										<h2>{t("common:registerFinds")}</h2>
									</div>
								</div>
								<p className="mt-3">{t("frontPage:registerText")}</p>
							</Link>
							<div className="spacer d-none d-md-block" />
							<Link to="/dataportal" className="mb-4">
								<div className="row align-items-center no-gutters see-registrations">
									<div className="col-6">
										<h2>{t("common:seeFinds")}</h2>
									</div>
									<div className="col-6">
										<img src={dataImg} alt="" />
									</div>
								</div>
								<p className="mt-3">{t("frontPage:seeMoreText")}</p>
							</Link>
						</div>
					</div>
				</div>
			</div>
			<div className="container">
				<div className="row">
					<div className="col-12 text-center pt-5 pb-5 citizen-scientist">
						<h1>{t("frontPage:becomeCitizenScientist")}</h1>
						<p>{t("frontPage:citizenScientistText")}</p>
						<Link to="/folkeforsker" className="read-more">
							{t("common:readMore")}
						</Link>
					</div>
				</div>
				<div className="row align-items-start">
					<div className="col-lg-4 mt-5 explore">
						<Link to="/livetIHavet">
							<div>
								<h2>{t("common:ourSelectedSpecies")}</h2>
							</div>
							<img src="/img/crab.jpg" alt="" />
						</Link>
					</div>
					<div className="col-lg-4 mt-5">
						<Statistics />
					</div>
					<div className="col-lg-4 mt-5">
						<ScientistCorner />
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
};

export default Frontpage;
export { Frontpage };
