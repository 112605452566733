import type { Report } from "./havfunnHelpers";
import React from "react";
import { LayerGroup, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import { useTranslation } from "react-i18next";
import moment from "moment";
import settings from "../../../settings";
import { havfunnIcons as icons } from "./icons";

const HavfunnLayer = ({ reports }: { reports: Report[] }): JSX.Element => {
	// Get translator
	const [t] = useTranslation();

	return (
		<LayerGroup>
			{reports.map(r => (
				<Marker
					key={r._id}
					position={L.latLng(r.latitude, r.longitude)}
					icon={icons[r.category]}
				>
					<Popup>
						<dl>
							<dt>{t("havfunn:category")}</dt>
							<dd>{r.category}</dd>
							<dt>{t("havfunn:species")}</dt>
							<dd>{r.validSpecie ?? r.species}</dd>
							<dt>{t("havfunn:date")}</dt>
							<dd>{moment(r.date).format("YYYY-MM-DD")}</dd>
							<dt>{t("havfunn:validated")}</dt>
							<dd>{r.isValidated ? t("yes") : t("no")}</dd>
						</dl>
						<a
							href={`${settings.HAVFUNN_URL}/viewReport/${r._id}`}
							target="_blank"
							rel="noopener noreferrer"
						>
							{t("havfunn:viewInHavfunn")}
						</a>
					</Popup>
				</Marker>
			))}
		</LayerGroup>
	);
};

export default HavfunnLayer;
export { HavfunnLayer };
