import { useEffect, useState } from "react";

const getWindowSize = () => {
	return { width: window.innerWidth, height: window.innerHeight };
};

export const useWindowSize = () => {
	const [windowSize, setWindowSize] = useState(getWindowSize());

	useEffect(() => {
		const handleResize = () => {
			setWindowSize(getWindowSize());
		};

		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	return {
		windowSize,
		sm: windowSize.width < 768,
		md: windowSize.width >= 768 && windowSize.width < 992,
		lg: windowSize.width >= 992 && windowSize.width < 1200,
		xl: windowSize.width >= 1200
	};
};
