import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCommentDots, faBars } from "@fortawesome/free-solid-svg-icons";
import settings from "../../settings";
import "./Navbar.scss";

const Navbar = (): JSX.Element => {
	const { t, i18n } = useTranslation();

	// Handle dropdown state
	const [showDropdown, setShowDropdown] = useState<boolean>(false);
	const [dropdownTopOffset, setDropdownTopOffset] = useState<string>("0");
	const navbar = useRef<HTMLElement>(null);
	const dropdown = useRef<HTMLDivElement>(null);
	useEffect(() => {
		setDropdownTopOffset(String(navbar.current?.clientHeight ?? 0) + "px");
	}, [navbar]);

	// Close the dropdown if a click is registered outside it
	useEffect(() => {
		// Don't do anything if the dropdown is not open
		if (!showDropdown) {
			return;
		}

		const handler = (e: MouseEvent) => {
			// Ignore clicks on the dropdown itself
			if (e.target !== dropdown.current) {
				setShowDropdown(false);
			}
		};

		document.addEventListener("click", handler);

		return () => {
			document.removeEventListener("click", handler);
		};
	}, [showDropdown]);

	return (
		<nav ref={navbar} className="navbar navbar-expand">
			{/* Brand */}
			<div className="navbar-header">
				<Link to="/" className="navbar-brand">
					<img className="mr-4" src="/img/logo.png" alt="" />
					{t("appName")}
				</Link>
			</div>
			{/* Nav elements */}
			<div id="colNav" className="collapse navbar-collapse">
				<ul className="nav navbar-nav ml-auto">
					{/* Register finds */}
					<li className="nav-item d-none d-md-block">
						<Link to="/registrerFunn" className="nav-link">
							{t("common:registerFinds")}
						</Link>
					</li>
					{/* See finds */}
					<li className="nav-item d-none d-md-block">
						<Link to="/dataportal" className="nav-link">
							{t("common:seeFinds")}
						</Link>
					</li>
					<li className="nav-item">
						<button
							className="btn"
							type="button"
							onClick={() => setShowDropdown(s => !s)}
						>
							<FontAwesomeIcon icon={faBars} />
						</button>
					</li>
				</ul>
			</div>
			{showDropdown ? (
				<div ref={dropdown} className="dropdown-hack" style={{ top: dropdownTopOffset }}>
					<ul className="list-unstyled">
						<li>
							<Link to="/">{t("common:frontPage")}</Link>
						</li>
						<li>
							<Link to="/registrerFunn">{t("common:registerFinds")}</Link>
						</li>
						<li>
							<Link to="/dataportal">{t("common:seeFinds")}</Link>
						</li>
						<li>
							<Link to="/livetIHavet">{t("common:ourSelectedSpecies")}</Link>
						</li>
					</ul>
					<ul className="list-unstyled">
						<li>
							<Link to="/om">{t("common:aboutMCS")}</Link>
						</li>
					</ul>
					<ul className="list-unstyled">
						<li>
							<FontAwesomeIcon icon={faCommentDots} /> {t("common:language")}
						</li>
						<li>
							{i18n.language !== "nb" ? (
								<button
									type="button"
									className="btn btn-link"
									onClick={() => i18n.changeLanguage("nb")}
								>
									Bytt til Norsk
								</button>
							) : (
								<button
									type="button"
									className="btn btn-link"
									onClick={() => i18n.changeLanguage("en")}
								>
									Switch to English
								</button>
							)}
						</li>
						{settings.NODE_ENV === "development" ? (
							<li>
								<button
									type="button"
									className="btn btn-link"
									onClick={() => i18n.changeLanguage("dev")}
								>
									Se språkkoder
								</button>
							</li>
						) : null}
					</ul>
				</div>
			) : null}
		</nav>
	);
};

export default Navbar;
export { Navbar };
