import React from "react";
import { Header } from "../common/Header";
import { Sidebar } from "../common/Sidebar";
import { Footer } from "../common/Footer";
import { Trans, useTranslation } from "react-i18next";
import "./About.scss";

const About = (): JSX.Element => {
	const { t } = useTranslation();

	return (
		<div className="register-finds">
			<Header>{t("about:header")}</Header>
			<div className="container">
				<div className="row">
					<div className="col-12 col-md-3">
						<Sidebar>
							<img
								className="img-fluid"
								src="/img/sidebar/salmon.jpg"
								alt=""
							/>
							<img
								className="img-fluid"
								src="/img/sidebar/urchin.jpg"
								alt=""
							/>
							<img className="img-fluid" src="/img/sidebar/crab.jpg" alt="" />
						</Sidebar>
					</div>
					<div className="col-12 col-md-9 text-md-justify">
						<div className="row">
							<div className="col-12">
								<h2 className="header-blue">{t("about:whatIs")}</h2>
								<p>{t("about:whatIsp1")}</p>
								<p>{t("about:whatIsp2")}</p>
								<p>{t("about:whatIsp3")}</p>
								<p>
									<Trans i18nKey="about:whatIsp4">
										Flere taksonomer sjekker det som kommer
										inn. Funn som en er trygge på,
										registreres i en samlet database, som
										igjen er koblet til{" "}
										<a
											href="https://artsdatabanken.no"
											target="_blank"
											rel="noreferrer"
										>
											artsdatabanken.no
										</a>
										, som er Norges offisielle sted for
										artsobservasjoner
									</Trans>
								</p>
							</div>
						</div>
						<div className="row">
							<div className="col-12">
								<h2 className="header-blue">{t("about:recordWhat")}</h2>
								<p>{t("about:recordWhatp1")}</p>
								<p>{t("about:recordWhatp2")}</p>
							</div>
						</div>
						<div className="row">
							<div className="col-12">
								<h2 className="header-blue">{t("about:seeWhat")}</h2>
								<p>{t("about:seeWhatp1")}</p>
							</div>
						</div>
						<div className="row">
							<div className="col-12">
								<h2 className="header-blue">
									{t("about:errorReporting")}
								</h2>
								<p>
									<Trans i18nKey="about:errorReportingp1">
										Hvis du finner feil eller andre ting som
										ikke fungerer, kontakt oss på{" "}
										<a href="mailto:dugnadhjelp@hi.no">
											dugnadhjelp@hi.no
										</a>
									</Trans>
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
};

export default About;
export { About };
