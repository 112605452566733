import React, { useRef } from "react";
import { BrowserRouter, Route, useHistory } from "react-router-dom";

import { Navbar } from "../components/common/Navbar";
import { Frontpage } from "../components/pages/Frontpage";
import { RegisterFind } from "../components/pages/RegisterFind";
import { CitizenScientist } from "../components/pages/CitizenScientist";
import { LifeInTheOcean } from "../components/pages/LifeInTheOcean";
import { Timeseries } from "../components/pages/Timeseries";
import { About } from "../components/pages/About";
import { Dataportal } from "../components/pages/Dataportal";
import { Todo } from "../components/pages/Todo";
import { ScientistCornerPost } from "../components/pages/ScientistCornerPost";
import { ScientistCornerIndex } from "../components/pages/ScientistCornerIndex";
import { ContactUs } from "../components/pages/ContactUs";
import { GoogleAnalytics } from "../components/common/GoogleAnalytics";

const ScrollToTopOnLocationChange = () => {
	const history = useHistory();

	history.listen(() => {
		window.scrollTo(0, 0);
	});

	return null;
};

const Router = (): JSX.Element => {
	const navbarRef = useRef<HTMLDivElement>(null);

	return (
		<BrowserRouter>
			<GoogleAnalytics />
			<ScrollToTopOnLocationChange />
			<div ref={navbarRef}>
				<Navbar />
			</div>
			<Route exact path="/">
				<Frontpage />
			</Route>
			<Route exact path="/registrerFunn">
				<RegisterFind />
			</Route>
			<Route exact path="/folkeforsker">
				<CitizenScientist />
			</Route>
			<Route exact path="/livetIHavet">
				<LifeInTheOcean />
			</Route>
			<Route exact path="/tidsserier">
				<Timeseries />
			</Route>
			<Route exact path="/om">
				<About />
			</Route>
			<Route exact path="/dataportal">
				<Dataportal navbar={navbarRef} />
			</Route>
			<Route exact path="/forskerhjørnet">
				<ScientistCornerIndex />
			</Route>
			<Route exact path="/forskerhjørnet/:id">
				<ScientistCornerPost />
			</Route>
			<Route exact path="/kontakt">
				<ContactUs />
			</Route>
			<Route exact path="/todo">
				<Todo />
			</Route>
		</BrowserRouter>
	);
};

export default Router;
export { Router };
