import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./Sidebar.scss";

const Sidebar = ({ children }: { children: JSX.Element | JSX.Element[] }): JSX.Element => {
	const { t } = useTranslation();

	const location = useLocation();

	const arrChildren = Array.isArray(children) ? children : [children];

	return (
		<div className="row sidebar">
			{location.pathname !== "/registrerFunn" ? (
				<div className="d-none d-md-block col-12">
					<Link to="/registrerFunn">
						<h3 className="sidebar-button">{t("common:registerFinds")}</h3>
					</Link>
				</div>
			) : null}
			<div className="d-none d-md-block col-12">
				<Link to="/dataportal">
					<h3 className="sidebar-button">{t("common:seeFinds")}</h3>
				</Link>
			</div>
			<div className="d-none d-md-block col-12">
				<Link to="/livetIHavet">
					<h3 className="sidebar-button">{t("common:lifeInTheOcean")}</h3>
				</Link>
			</div>
			{arrChildren.map((child, i) => (
				<div key={i} className="d-none d-md-block col-12">
					{child}
				</div>
			))}
		</div>
	);
};

export default Sidebar;
export { Sidebar };
