const months = ["jan", "feb", "mar", "apr", "mai", "jun", "jul", "aug", "sep", "okt", "nov", "des"];

const formatDate = (date: Date): string => {
	const day = date.getDate();
	const month = months[date.getMonth()];
	const hours = String(date.getHours()).padStart(2, "0");
	const minutes = String(date.getMinutes()).padStart(2, "0");

	return `${day}. ${month}. kl. ${hours}:${minutes}`;
};

export default formatDate;
export { formatDate };
