import React from "react";
import { Header } from "../common/Header";
import { Sidebar } from "../common/Sidebar";
import { Footer } from "../common/Footer";
import settings from "../../settings";
import { Trans, useTranslation } from "react-i18next";
import "./RegisterFind.scss";

const RegisterFind = (): JSX.Element => {
	const { t } = useTranslation();

	return (
		<div className="register-finds">
			<Header>Registrer funn</Header>
			<div className="container">
				<div className="row text-center to-systems">
					<div className="col-12 havfunn">
						<a href={`${settings.HAVFUNN_URL}/newObservation`}>
							<h2>Enkeltfunn</h2>
						</a>
					</div>
					<div className="col-12 tidsserier">
						<a href={`${settings.TIDSSERIER_URL}/mainView`}>
							<h2>Tidsserie</h2>
						</a>
					</div>
					<div className="col-12 excursion">
						<a href={`${settings.EXCURSION_URL}/`}>
							<h2>Ekskursjon</h2>
						</a>
					</div>
				</div>
				<div className="row">
					<div className="col-12 col-md-3">
						<Sidebar>
							<img
								className="img-fluid"
								src="/img/sidebar/salmon.jpg"
								alt=""
							/>
							<img
								className="img-fluid"
								src="/img/sidebar/urchin.jpg"
								alt=""
							/>
							<img className="img-fluid" src="/img/sidebar/crab.jpg" alt="" />
						</Sidebar>
					</div>
					<div className="col-12 col-md-9 text-justify">
						<div className="row">
							<div className="col-12">
								<h2 className="header-blue">
									{t("registerFinds:terms")}
								</h2>
								<p>{t("registerFinds:termsp1")}</p>
								<p>
									<Trans i18nKey="registerFinds:termsp2">
										Når du tar i bruk løsningen, aksepterer
										du{" "}
										<a
											href={`${settings.HAVFUNN_URL}/terms`}
										>
											brukervilkårene
										</a>
										. Brukervilkårene regulerer partenes
										rettigheter og plikter ved bruk. Det
										vises også til virksomhetens
										personvernerklæring. Det er en
										forutsetning at du har akseptert
										brukervilkårene før du kan benytte deg
										av løsningen.
									</Trans>
								</p>
							</div>
						</div>
						<div className="row">
							<div className="col-12">
								<h2 className="header-blue">
									{t("registerFinds:what")}
								</h2>
								<p>{t("registerFinds:whatp1")}</p>
								<p>{t("registerFinds:whatp2")}</p>
								<p>{t("registerFinds:whatp3")}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
};

export default RegisterFind;
export { RegisterFind };
