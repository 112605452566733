import { useEffect } from "react";
import ReactGA from "react-ga";
import { useLocation } from "react-router-dom";
import settings from "../../settings";

ReactGA.initialize(settings.GA_TOKEN, { debug: settings.NODE_ENV === "development" });

const GoogleAnalytics = (): null => {
	const location = useLocation();

	useEffect(() => {
		ReactGA.pageview(location.pathname);
	}, [location.pathname]);

	return null;
};

export default GoogleAnalytics;
export { GoogleAnalytics };
