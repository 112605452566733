import React from "react";
import { Header } from "../common/Header";
import { Sidebar } from "../common/Sidebar";
import { Footer } from "../common/Footer";
import { useTranslation } from "react-i18next";
import "./Timeseries.scss";

const Timeseries = (): JSX.Element => {
	const { t } = useTranslation();

	return (
		<div className="register-finds">
			<Header>{t("timeSeries:header")}</Header>
			<div className="container">
				<div className="row">
					<div className="col-12 col-md-3">
						<Sidebar>
							<img
								className="img-fluid"
								src="/img/sidebar/oyster.jpg"
								alt=""
							/>
							<img
								className="img-fluid"
								src="/img/sidebar/mussels.jpg"
								alt=""
							/>
							<img className="img-fluid" src="/img/sidebar/kelp.jpg" alt="" />
						</Sidebar>
					</div>
					<div className="col-12 col-md-9 text-justify">
						<div className="row">
							<div className="col-12">
								<h2 className="header-blue">
									{t("timeSeries:whatIs")}
								</h2>
								<p>{t("timeSeries:whatIsp1")}</p>
								<p>{t("timeSeries:whatIsp2")}</p>
							</div>
						</div>
						<div className="row">
							<div className="col-12">
								<h2 className="header-blue">
									{t("timeSeries:observationSeries")}
								</h2>
								<p>{t("timeSeries:observationSeriesp1")}</p>
							</div>
						</div>
						<div className="row">
							<div className="col-12">
								<h2 className="header-blue">
									{t("timeSeries:observations")}
								</h2>
								<p>{t("timeSeries:observationsp1")}</p>
							</div>
						</div>
						<div className="row">
							<div className="col-12">
								<h2 className="header-blue">
									{t("timeSeries:photos")}
								</h2>
								<p>{t("timeSeries:photosp1")}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
};

export default Timeseries;
export { Timeseries };
