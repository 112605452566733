import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import settings from "../settings";

import en from "./en.i18n.json";
import nb from "./nb.i18n.json";

void i18next
	// Autodetect language settings from browser. Includes caching the result until next time
	.use(LanguageDetector)
	// pass the i18n instance to react-i18next.
	.use(initReactI18next)
	.init({
		// Debug in dev mode
		debug: settings.NODE_ENV === "development",
		interpolation: {
			// React already escapes strings
			escapeValue: false
		},
		resources: {
			en,
			nb
		}
	})
	.then(() => {
		// Do some magic fuckery to get correct language from the autodetect
		const langCode = i18next.language.toLowerCase();
		if (langCode.includes("nb") || langCode.includes("no")) {
			void i18next.changeLanguage("nb");
		} else {
			void i18next.changeLanguage("en");
		}
	});

export default i18next;
export { i18next };
