import React from "react";
import { Dataportal as ActualDataportal } from "../common/Dataportal";

const Dataportal = ({ navbar }: { navbar: React.RefObject<HTMLDivElement> }): JSX.Element => {
	return (
		<div className="container-fluid">
			<ActualDataportal navbar={navbar} />
		</div>
	);
};

export default Dataportal;
export { Dataportal };
