import React from "react";
import { Header } from "../common/Header";
import { Sidebar } from "../common/Sidebar";
import { Footer } from "../common/Footer";
import { Trans, useTranslation } from "react-i18next";
import "./ContactUs.scss";

const ContactUs = (): JSX.Element => {
	const { t } = useTranslation();

	return (
		<div className="contact-us">
			<Header>{t("contactUs:contactUs")}</Header>
			<div className="container">
				<div className="row">
					<div className="col-12 col-md-3">
						<Sidebar>
							<img
								className="img-fluid"
								src="/img/sidebar/salmon.jpg"
								alt=""
							/>
							<img
								className="img-fluid"
								src="/img/sidebar/urchin.jpg"
								alt=""
							/>
							<img className="img-fluid" src="/img/sidebar/crab.jpg" alt="" />
						</Sidebar>
					</div>
					<div className="col-12 col-md-9 text-justify">
						<div className="row">
							<div className="col-12">
								<h2 className="header-blue">
									{t("contactUs:contactUs")}
								</h2>
								<p>
									<Trans i18nKey="contactUs:p1">
										Dersom du har tilbakemeldinger til
										Dugnad for Havet, send gjerne en e-post
										til{" "}
										<a href="mailto:dugnadhjelp@hi.no">
											dugnadhjelp@hi.no
										</a>
									</Trans>
								</p>
								<p>
									<Trans i18nKey="contactUs:p2">
										Merk: Funn skal registreres i appen,
										ikke sendes på mail.
									</Trans>
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
};

export default ContactUs;
export { ContactUs };
