import React, { useState, useRef, useEffect } from "react";
import { Header } from "../common/Header";
import { Footer } from "../common/Footer";
import * as R from "ramda";
import $ from "jquery";
import { Trans, useTranslation } from "react-i18next";
import "./LifeInTheOcean.scss";

const LifeInTheOcean = (): JSX.Element => {
	const [openPhotos, setOpenPhotos] = useState<string | null>(null);
	const { t } = useTranslation();

	return (
		<div className="life-in-the-ocean">
			<Header>{t("lifeInTheOcean:header")}</Header>
			<div className="container">
				<div className="row">
					<div className="col-12 text-md-justify bottom-border">
						<p>
							<strong>{t("lifeInTheOcean:headerText")}</strong>
						</p>
					</div>
				</div>
				<div className="row bottom-border">
					<div className="col-12 col-md-6 text-md-justify">
						<div className="bottom-border">
							<div className="registration-type-header">
								<h2 className="header-blue">
									{t("havfunnCategories:fishSpecies")}
								</h2>
								<button
									className="btn btn-link"
									type="button"
									onClick={() => setOpenPhotos("fishSpecies")}
								>
									{t("lifeInTheOcean:seePhotos")} &gt;&gt;
								</button>
							</div>
							<p>
								<Trans i18nKey="lifeInTheOcean:speciesOfFishp1">
									{" "}
									<strong>uvanlig fiskeart</strong>
								</Trans>
							</p>
						</div>
						<div className="bottom-border">
							<div className="registration-type-header">
								<h2 className="header-blue">
									{t("havfunnCategories:atlanticBluefinTuna")}
								</h2>
								<button
									className="btn btn-link"
									type="button"
									onClick={() =>
										setOpenPhotos("atlanticBluefinTuna")
									}
								>
									{t("lifeInTheOcean:seePhotos")} &gt;&gt;
								</button>
							</div>
							<p>{t("lifeInTheOcean:atlanticBluefinTunap1")}</p>
						</div>
						<div className="bottom-border">
							<div className="registration-type-header">
								<h2 className="header-blue">
									{t("havfunnCategories:fishTag")}
								</h2>
								<button
									className="btn btn-link"
									type="button"
									onClick={() => setOpenPhotos("fishTag")}
								>
									{t("lifeInTheOcean:seePhotos")} &gt;&gt;
								</button>
							</div>
							<p>{t("lifeInTheOcean:taggingp1")}</p>
						</div>
						<div className="bottom-border">
							<div className="registration-type-header">
								<h2 className="header-blue">
									{t("havfunnCategories:jellyfishSpecies")}
								</h2>
								<button
									className="btn btn-link"
									type="button"
									onClick={() =>
										setOpenPhotos("jellyfishSpecies")
									}
								>
									{t("lifeInTheOcean:seePhotos")} &gt;&gt;
								</button>
							</div>
							<p>{t("lifeInTheOcean:jellyfishp1")}</p>
							<p>{t("lifeInTheOcean:jellyfishp2")}</p>
						</div>
						<div>
							<div className="registration-type-header">
								<h2 className="header-blue">
									{t("havfunnCategories:baskingShark")}
								</h2>
								<button
									className="btn btn-link"
									type="button"
									onClick={() => setOpenPhotos("baskingShark")}
								>
									{t("lifeInTheOcean:seePhotos")} &gt;&gt;
								</button>
							</div>
							<p>{t("lifeInTheOcean:baskingSharkp1")}</p>
						</div>
					</div>
					<div className="col-12 col-md-6 text-md-justify">
						<div className="greenblue-thing">
							<div className="registration-type-header">
								<h2>{t("lifeInTheOcean:nonNativeSpeciesheader")}</h2>
								<button
									className="btn btn-link"
									type="button"
									onClick={() =>
										setOpenPhotos("introducedSpecies")
									}
								>
									{t("lifeInTheOcean:seePhotos")} &gt;&gt;
								</button>
							</div>
							<p>{t("lifeInTheOcean:nonNativeSpeciesp1")}</p>
							<p>{t("lifeInTheOcean:nonNativeSpeciesp2")}</p>
							<p>{t("lifeInTheOcean:nonNativeSpeciesp3")}</p>
						</div>
						<div className="bottom-border">
							<div className="registration-type-header">
								<h2 className="header-blue">
									{t("havfunnCategories:seaMammals")}
								</h2>
								<button
									className="btn btn-link"
									type="button"
									onClick={() => setOpenPhotos("seaMammals")}
								>
									{t("lifeInTheOcean:seePhotos")} &gt;&gt;
								</button>
							</div>
							<p>{t("lifeInTheOcean:seaMammalsp1")}</p>
						</div>
						<div className="bottom-border">
							<div className="registration-type-header">
								<h2 className="header-blue">
									{t("havfunnCategories:coralSpecies")}
								</h2>
								<button
									className="btn btn-link"
									type="button"
									onClick={() => setOpenPhotos("coralSpecies")}
								>
									{t("lifeInTheOcean:seePhotos")} &gt;&gt;
								</button>
							</div>
							<p>{t("lifeInTheOcean:coralsp1")}</p>
							<p>{t("lifeInTheOcean:coralsp2")}</p>
						</div>
						<div>
							<div className="registration-type-header">
								<h2 className="header-blue">
									{t("havfunnCategories:algalGrowth")}
								</h2>
								<button
									className="btn btn-link"
									type="button"
									onClick={() => setOpenPhotos("algalGrowth")}
								>
									{t("lifeInTheOcean:seePhotos")} &gt;&gt;
								</button>
							</div>
							<p>
								<Trans i18nKey="lifeInTheOcean:algaep1">
									<strong />
								</Trans>
							</p>
							<p>
								<Trans i18nKey="lifeInTheOcean:algaep2">
									Har du spørsmål om algeoppblomstringen kan du
									også gå til{" "}
									<a
										href="https://algestatus.hi.no"
										target="_blank"
										rel="noopener noreferrer"
									>
										algestatus.hi.no
									</a>
								</Trans>
							</p>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-12 greenblue-thing">
						<h2>{t("lifeInTheOcean:otherSpecies")}</h2>
						<p>{t("lifeInTheOcean:otherSpeciesp1")}</p>
					</div>
				</div>
			</div>
			{openPhotos !== null ? (
				<PhotoModal openPhotos={openPhotos} onClose={() => setOpenPhotos(null)} />
			) : null}
			<Footer />
		</div>
	);
};

const exampleData = new Map<
	string,
	Array<{
		scientificName: string;
		norwegianName?: string;
		englishName?: string;
		translateCode?: string;
		fileName: string;
		photoCredit: string;
	}>
>([
	[
		"fishSpecies",
		[
			{
				scientificName: "Merluccius merluccius",
				norwegianName: "Lysing",
				englishName: "European Hake",
				fileName: "merluccius_merluccius.jpg",
				photoCredit: "Erling Svensen"
			},
			{
				scientificName: "Dicentrarchus labrax",
				norwegianName: "Havabbor",
				englishName: "European Seabass",
				fileName: "dicentrarchus_labrax.jpg",
				photoCredit: "Erling Svensen"
			},
			{
				scientificName: "Cyclopterus Lumpus",
				norwegianName: "Rognkjeks",
				englishName: "Lumpsucker, Lumpfish",
				fileName: "cyclopterus_lumpus.jpg",
				photoCredit: "Erling Svensen"
			},
			{
				scientificName: "Labrus Mixtus",
				norwegianName: "Blåstål (♂) , Rødnebb (♀)",
				englishName: "Cuckoo Wrasse",
				fileName: "labrus_mixtus.jpg",
				photoCredit: "Erling Svensen"
			},
			{
				scientificName: "Zeus faber",
				norwegianName: "Sanktpetersfisk",
				englishName: "John Dory",
				fileName: "zeus_faber.jpg",
				photoCredit: "Erling Svensen"
			},
			{
				scientificName: "Chelon labrosus",
				norwegianName: "Tykkleppet Multe",
				englishName: "Thicklip Grey Mullet",
				fileName: "chelon_labrosus.jpg",
				photoCredit: "Erling Svensen"
			},
			{
				scientificName: "Mullus surmuletus",
				norwegianName: "Mulle",
				englishName: "Surmullet, Striped Red Mullet",
				fileName: "mullus_surmuletus.jpg",
				photoCredit: "Erling Svensen"
			}
		]
	],
	[
		"introducedSpecies",
		[
			{
				scientificName: "Paralithodes camtschaticus",
				translateCode: "redKingCrab",
				fileName: "1_Kongekrabbe_001.jpg",
				photoCredit: "Anette Knudsen"
			},
			{
				scientificName: "Chionoecetes opilio",
				translateCode: "snowCrab",
				fileName: "2_Snøkrabbe_001.jpg",
				photoCredit: "Havforskingsinstituttet"
			},
			{
				scientificName: "Homarus americanus",
				translateCode: "americanLobster",
				fileName: "3_Amerikansk_hummer_001.jpg",
				photoCredit: "Havforskingsinstituttet"
			},
			{
				scientificName: "Cancer irroratus",
				translateCode: "",
				fileName: "4_Cancer_irroratus_stor.jpg",
				photoCredit: "Janet MacCausland, Wikimedia commons"
			},
			{
				scientificName: "Hemigrapsus sanguineus",
				translateCode: "asianShoreCrab",
				fileName: "5_Hemigrapsus_sanguineus_stor.jpg",
				photoCredit: "Wikimedia commons"
			},
			{
				scientificName: "Hemigrapsus takanoi",
				translateCode: "brushClawedShoreCrab",
				fileName: "6_Hemigrapsus_takanoi_001.jpg",
				photoCredit: "Hans Hillewaert, Wikimedia commons"
			},
			{
				scientificName: "Caprella mutica",
				translateCode: "japaneseSkeletonShrimp",
				fileName: "7_Japansk_spøkelseskreps_001.jpg",
				photoCredit: "Rudolf Svensen"
			},
			{
				scientificName: "Crassostrea gigas",
				translateCode: "pacificOyster",
				fileName: "8_Stillehavsøsters_001.jpg",
				photoCredit: "Vivian Husa"
			},
			{
				scientificName: "Crepidula fornicata",
				translateCode: "commonSlipperShell",
				fileName: "9_Tøffelsnegl.jpg",
				photoCredit: "Vivian Husa"
			},
			{
				scientificName: "Ocinebrellus inornatus",
				translateCode: "asianBoreSnail",
				fileName: "10_Ocinebrellus_inornatus_stor.jpg",
				photoCredit: "Wikimedia Commons"
			},
			{
				scientificName: "Styela clava",
				translateCode: "stalkedSeaSquirt",
				fileName: "11_Styela_clava.jpg",
				photoCredit: "Erling Svensen"
			},
			{
				scientificName: "Didemnum vexillum",
				translateCode: "carpetSeaSquirt",
				fileName: "12_Didemnum_vexillum.jpg",
				photoCredit: "Rosana Moreira da Rocha, Worms"
			},
			{
				scientificName: "Botrylloides violaceus",
				translateCode: "",
				fileName: "13_Botrylloides_violaceus_001.jpg",
				photoCredit: "Erling Svensen"
			},
			{
				scientificName: "Schizoporella japonica",
				translateCode: "",
				fileName: "14_Schizoporella_japonica_001.jpg",
				photoCredit: "Rudolf Svensen"
			},
			{
				scientificName: "Neogobius melanostomus",
				translateCode: "roundGoby",
				fileName: "15_Svartmunnet_kutling.jpg",
				photoCredit: "Peter van der Sluijs, Wikimedia commons"
			},
			{
				scientificName: "Gracilaria vermiculophylla",
				translateCode: "",
				fileName: "16_Gracilaria_vermiculophylla_001.jpg",
				photoCredit: "Rudolf Svensen"
			},
			{
				scientificName: "Sargassum muticum",
				translateCode: "japaneseWireweed",
				fileName: "17_Japansk_drivtang.jpg",
				photoCredit: "Vivian Husa"
			}
		]
	],
	[
		"jellyfishSpecies",
		[
			{
				scientificName: "Aurelia aurita",
				translateCode: "commonJellyfish",
				fileName: "glassmanet_001.jpg",
				photoCredit: "Erling Svensen"
			},
			{
				scientificName: "Cyanea capillata",
				translateCode: "lionsManeJellyfish",
				fileName: "brennmanet_001.jpg",
				photoCredit: "Erling Svensen"
			},
			{
				scientificName: "Cyanea lamarchii",
				translateCode: "blueJellyfish",
				fileName: "blå_brennmanet_001.jpg",
				photoCredit: "Erling Svensen"
			},
			{
				scientificName: "Chrysaora hysoscella",
				translateCode: "compassJellyfish",
				fileName: "kompassmanet_001.jpg",
				photoCredit: "Havforskingsinstituttet"
			},
			{
				scientificName: "Periphylla periphylla",
				translateCode: "helmetJellyfish",
				fileName: "kronemanet_001.jpg",
				photoCredit: "Erling Svensen"
			},
			{
				scientificName: "Rhizostoma pulmo",
				translateCode: "barrelJellyfish",
				fileName: "lungemanet.jpg",
				photoCredit: "Erling Svensen"
			},
			{
				scientificName: "Pelagia noctiluca",
				translateCode: "",
				fileName: "pelagia_noctiluca_001.jpg",
				photoCredit: "Erling Svensen"
			},
			{
				scientificName: "Aequorea sp",
				translateCode: "crystalJelly",
				fileName: "krystallmanet_001.jpg",
				photoCredit: "Erling Svensen"
			},
			{
				scientificName: "Leuckartiara sp",
				translateCode: "",
				fileName: "leuckartiara_sp_001.jpg",
				photoCredit: "Erling Svensen"
			},
			{
				scientificName: "Tima bairdii",
				translateCode: "",
				fileName: "tima_bairdii_001.jpg",
				photoCredit: "Erling Svensen"
			},
			{
				scientificName: "Gonionemus vertens",
				translateCode: "",
				fileName: "Gonionemus vertens .jpg",
				photoCredit: "Erling Svensen"
			},
			{
				scientificName: "Physophora hydrostatica",
				translateCode: "hulaSkirtJellyfish",
				fileName: "hulaskjørt_manet_001.jpg",
				photoCredit: "Erling Svensen"
			},
			{
				scientificName: "Pleurobrachia pileus",
				translateCode: "seaGooseberry",
				fileName: "sjøstikkelsbær_001.jpg",
				photoCredit: "Erling Svensen"
			},
			{
				scientificName: "Beroe cucumis",
				translateCode: "cucumberJellyfish",
				fileName: "agurkmanet_001.jpg",
				photoCredit: "Erling Svensen"
			},
			{
				scientificName: "Bolinopsis infundibulum",
				translateCode: "lobeJellyFish",
				fileName: "lobemanet_001.jpg",
				photoCredit: "Erling Svensen"
			},
			{
				scientificName: "Mnemiopsis leidyi",
				translateCode: "seaWalnut",
				fileName: "amerikansk_lobemanet_001.jpg",
				photoCredit: "Erling Svensen"
			}
		]
	],
	[
		"coralSpecies",
		[
			{
				scientificName: "Primnoa resedaeformis",
				translateCode: "primnoaResedaeformis",
				fileName: "risengrynskorall.jpg",
				photoCredit: "Havforskingsinstituttet"
			},
			{
				scientificName: "Lophelia pertusa (living)",
				translateCode: "lopheliaPertusa",
				fileName: "øyekorall_levende.jpg",
				photoCredit: "Havforskingsinstituttet"
			},
			{
				scientificName: "Lophelia pertusa (dead)",
				translateCode: "lopheliaPertusa",
				fileName: "øyekorall_dead.jpg",
				photoCredit: "Havforskingsinstituttet"
			},
			{
				scientificName: "Paragorgia arborea",
				translateCode: "paragorgiaArborea",
				fileName: "sjøtre.jpg",
				photoCredit: "Havforskingsinstituttet"
			},
			{
				scientificName: "Paramuricea placomus",
				translateCode: "paramuriceaPlacomus",
				fileName: "sjøbusk.jpg",
				photoCredit: "Havforskingsinstituttet"
			},
			{
				scientificName: "Madrepora oculata",
				translateCode: "madreporaOculata",
				fileName: "sikksakkkorall.jpg",
				photoCredit: "Havforskingsinstituttet"
			},
			{
				scientificName: "Nephthyidae",
				translateCode: "nephthyidae",
				fileName: "blomkålkorall.jpg",
				photoCredit: "Havforskingsinstituttet"
			}
		]
	],
	[
		"mackarel",
		[
			{
				scientificName: "Scomber scombrus",
				translateCode: "mackarel",
				fileName: "mackarel1.jpg",
				photoCredit: "Erling Svensen"
			},
			{
				scientificName: "Scomber scombrus",
				translateCode: "mackarel",
				fileName: "mackarel2.jpg",
				photoCredit: "Erlend Astad Lorentzen"
			}
		]
	],
	[
		"atlanticBluefinTuna",
		[
			{
				scientificName: "Thunnus thynnus",
				translateCode: "atlanticBluefinTuna",
				fileName: "tuna2.jpg",
				photoCredit: "Endre Hopland"
			},
			{
				scientificName: "Thunnus thynnus",
				translateCode: "atlanticBluefinTuna",
				fileName: "tuna3.jpg",
				photoCredit: "Erlend Astad Lorentzen"
			},
			{
				scientificName: "Thunnus thynnus",
				translateCode: "atlanticBluefinTuna",
				fileName: "tuna4.jpg",
				photoCredit: "Martin Wiech"
			},
			{
				scientificName: "Thunnus thynnus",
				translateCode: "atlanticBluefinTuna",
				fileName: "tuna5.jpg",
				photoCredit: "Øyvind Tangen"
			}
		]
	],
	[
		"baskingShark",
		[
			{
				scientificName: "Cetorhinus maximus",
				translateCode: "baskingShark",
				fileName: "baskingshark.jpg",
				photoCredit: "Nils Øien / Havforskningsinstituttet"
			},
			{
				scientificName: "Cetorhinus maximus",
				translateCode: "baskingShark",
				fileName: "brugde1.jpg",
				photoCredit: "Erik Zakariassen"
			},
			{
				scientificName: "Cetorhinus maximus",
				translateCode: "baskingShark",
				fileName: "brugde2.jpg",
				photoCredit: "Asbjørn Johansen"
			},
			{
				scientificName: "Cetorhinus maximus",
				translateCode: "baskingShark",
				fileName: "brugde3.jpg",
				photoCredit: "Asbjørn Johansen"
			}
		]
	],
	[
		"fishTag",
		[
			{
				scientificName: "Homarus gammarus",
				translateCode: "lobster",
				fileName: "lobster.jpg",
				photoCredit: "Erling Svensen / Havforskningsinstituttet"
			},
			{
				scientificName: "Gadus morhua",
				translateCode: "cod",
				fileName: "cod.jpg",
				photoCredit: "Erling Svensen / Havforskningsinstituttet"
			},
			{
				scientificName: "Fish tag",
				translateCode: "fishTag",
				fileName: "merke1.jpg",
				photoCredit: "Havforskningsinstituttet"
			},
			{
				scientificName: "Fish tag",
				translateCode: "fishTagTagNumber",
				fileName: "merke2.jpg",
				photoCredit: "Havforskningsinstituttet"
			},
			{
				scientificName: "Fish tag",
				translateCode: "fishTagReward",
				fileName: "merke3.jpg",
				photoCredit: "Havforskningsinstituttet"
			}
		]
	],
	[
		"seaMammals",
		[
			{
				scientificName: "Halichoerus grypus",
				translateCode: "greySeal",
				fileName: "havert_gruppe.jpg",
				photoCredit: "Michael Poltermann / Havforskningsinstituttet"
			},
			{
				scientificName: "Halichoerus grypus",
				translateCode: "greySeal",
				fileName: "havert_2.jpg",
				photoCredit: "Michael Poltermann / Havforskningsinstituttet"
			},
			{
				scientificName: "Halichoerus grypus",
				translateCode: "greySeal",
				fileName: "havert_mor_unge.jpg",
				photoCredit: "Michael Poltermann / Havforskningsinstituttet"
			},
			{
				scientificName: "Halichoerus grypus",
				translateCode: "greySeal",
				fileName: "havert_mor_unge_2.jpg",
				photoCredit: "Michael Poltermann / Havforskningsinstituttet"
			},
			{
				scientificName: "Odobenus rosmarus",
				translateCode: "walrus",
				fileName: "hvalross_1.jpg",
				photoCredit: "Gen Nakamura"
			},
			{
				scientificName: "Odobenus rosmarus",
				translateCode: "walrus",
				fileName: "hvalross.jpg",
				photoCredit: "Gen Nakamura"
			},
			{
				scientificName: "Odobenus rosmarus",
				translateCode: "walrus",
				fileName: "hvalross_2.jpg",
				photoCredit: "Kathrine A. Ryeng / Havforskningsinstituttet"
			},
			{
				scientificName: "Megaptera novaeangliae",
				translateCode: "humpbackWhale",
				fileName: "knølhval_2.jpg",
				photoCredit: "Kathrine A. Ryeng / Havforskningsinstituttet"
			},
			{
				scientificName: "Megaptera novaeangliae",
				translateCode: "humpbackWhale",
				fileName: "knølhval_4.jpg",
				photoCredit: "Kathrine A. Ryeng / Havforskningsinstituttet"
			},
			{
				scientificName: "Megaptera novaeangliae",
				translateCode: "humpbackWhale",
				fileName: "knølhval_6.jpg",
				photoCredit: "Kjell-Arne Fagerheim / Havforskningsinstituttet"
			},
			{
				scientificName: "Megaptera novaeangliae",
				translateCode: "humpbackWhale",
				fileName: "knølhval_7.jpg",
				photoCredit: "Kjell-Arne Fagerheim / Havforskningsinstituttet"
			},
			{
				scientificName: "Phoca vitulina",
				translateCode: "harbourSeal",
				fileName: "steinkobbe_1.jpg",
				photoCredit: "Michael Poltermann / Havforskningsinstituttet"
			},
			{
				scientificName: "Phoca vitulina",
				translateCode: "harbourSeal",
				fileName: "steinkobbe_5.jpg",
				photoCredit: "Michael Poltermann / Havforskningsinstituttet"
			},
			{
				scientificName: "Phoca vitulina",
				translateCode: "harbourSeal",
				fileName: "steinkobbe_6.jpg",
				photoCredit: "Michael Poltermann / Havforskningsinstituttet"
			},
			{
				scientificName: "Phoca vitulina",
				translateCode: "harbourSeal",
				fileName: "steinkobbe_7.jpg",
				photoCredit: "Michael Poltermann / Havforskningsinstituttet"
			}
		]
	],
	[
		"algalGrowth",
		[
			{
				scientificName: "Color Example",
				fileName: "algae_6.jpg",
				photoCredit: "Havforskningsinstituttet"
			},
			{
				scientificName: "Chrysochromulina",
				fileName: "algae.jpg",
				photoCredit: "Landsat 8/NASA. NASA ikke-kommersiell"
			},
			{
				scientificName: "Chrysochromulina",
				fileName: "algae_2.jpg",
				photoCredit: "Havforskningsinstituttet"
			},
			{
				scientificName: "Chrysochromulina",
				fileName: "algae_3.jpg",
				photoCredit: "Havforskningsinstituttet"
			},
			{
				scientificName: "Chrysochromulina",
				fileName: "algae_4.jpg",
				photoCredit: "Lamiot"
			},
			{
				scientificName: "Chrysochromulina",
				fileName: "algae_5.jpg",
				photoCredit: "Havforskningsinstituttet"
			}
		]
	]
]);

const PhotoModal = ({ openPhotos, onClose }: { openPhotos: string; onClose: () => void }) => {
	const { t } = useTranslation();

	// Need a reference to the modal div
	const modalRef = useRef<HTMLDivElement>(null);

	// Run onClose when the modal closes for any reason
	useEffect(() => {
		if (R.isNil(modalRef.current)) {
			return;
		}

		const modal = $(modalRef.current);
		modal.on("hidden.bs.modal", onClose);

		return () => {
			modal.off("hidden.bs.modal", onClose);
		};
	}, [onClose]);

	// Show the modal immediately when mounted
	useEffect(() => {
		if (R.isNil(modalRef.current)) {
			return;
		}

		$(modalRef.current).modal("show");
	});

	return (
		<div ref={modalRef} className="modal fade" role="dialog">
			<div className="modal-dialog" role="document">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title">{t(`havfunnCategories:${openPhotos}`)}</h5>
						<button
							type="button"
							className="close"
							data-dismiss="modal"
							aria-label="Close"
						>
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div className="modal-body">
						<ul className="list-unstyled">
							{(exampleData.get(openPhotos) ?? []).map(obj => {
								/* eslint-disable no-mixed-spaces-and-tabs, prettier/prettier */
								const translatedName = R.isNil(obj.translateCode)
									? ""
									: t(
										`lifeInTheOcean:${openPhotos}:${obj.translateCode}`
									  );
								/* eslint-enable no-mixed-spaces-and-tabs, prettier/prettier */

								return (
									<li
										key={obj.fileName}
										className="list-group-item"
									>
										<h4>
											<i>{obj.scientificName}</i>
										</h4>
										{obj.norwegianName ? (
											<h5>{obj.norwegianName}</h5>
										) : null}
										{obj.englishName ? (
											<h5>{obj.englishName}</h5>
										) : null}
										{obj.translateCode ? (
											<h5>{translatedName}</h5>
										) : null}
										<img
											className="infoModalImage"
											src={`/img/examples/${openPhotos}/${obj.fileName}`}
											alt={translatedName}
										/>
										<p className="text-center">
											<small>
												Foto: {obj.photoCredit}
											</small>
										</p>
									</li>
								);
							})}
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
};

export default LifeInTheOcean;
export { LifeInTheOcean };
