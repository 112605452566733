import React, { useState, useEffect } from "react";
import { Header } from "../common/Header";
import { Sidebar } from "../common/Sidebar";
import { Footer } from "../common/Footer";
import { Link, useRouteMatch } from "react-router-dom";
import settings from "../../settings";
import * as R from "ramda";
import { formatDate } from "../../helpers/formatDate";
import { useTranslation } from "react-i18next";
import "./ScientistCornerPost.scss";

interface Post {
	ingress: string;
	text: string;
	author?: string;
	url?: string;
	createdAt: Date;
}

type RawPost = Omit<Post, "createdAt"> & { createdAt: string };

const ScientistCornerPost = (): JSX.Element => {
	const { t } = useTranslation();

	const id = useRouteMatch<{ id: string }>().params.id;
	const [post, setPost] = useState<Post | null>(null);
	useEffect(() => {
		// XXX Unlikely race condition here. If the ID changes before the fetch is complete, it could
		// potentially cause the old post to be put in the state after the new one
		const fn = () =>
			void fetch(`${settings.ADMIN_URL}/api/scientistCorner/getPost?id=${id}`)
				.then(res => res.json() as Promise<RawPost>)
				.then(res => ({
					...res,
					createdAt: new Date(res.createdAt)
				}))
				.then(setPost)
				.catch(err => {
					console.error(err);
				});

		fn();
	}, [id]);

	return (
		<div>
			<Header>{t("scientistCorner:scientistCorner")}</Header>
			<div className="container scientist-corner-post">
				<div className="row">
					<div className="col-12 col-md-3">
						<Sidebar>
							<img
								className="img-fluid"
								src="/img/sidebar/mussels.jpg"
								alt=""
							/>
							<img className="img-fluid" src="/img/sidebar/kelp.jpg" alt="" />
						</Sidebar>
					</div>
					{R.isNil(post) ? (
						<p>Laster...</p>
					) : (
						<div className="col-12 col-md-9 text-md-justify">
							<div className="row">
								<div className="col-12">
									<h2>{post.ingress}</h2>
									<time dateTime={post.createdAt.toISOString()}>
										{formatDate(post.createdAt)}
									</time>
								</div>
							</div>
							<div className="row mt-4">
								<div className="col-12">
									{post.text.split("\n").map((text, i) => (
										<p key={i}>{text}</p>
									))}
								</div>
							</div>
							{R.isNil(post.author) ? null : (
								<div className="row">
									<div className="col-12">
										<p className="author">{post.author}</p>
									</div>
								</div>
							)}
							{R.isNil(post.url) ? null : (
								<div className="row">
									<div className="col-12">
										<a
											href={post.url}
											target="_blank"
											rel="noreferrer"
										>
											{post.url}
										</a>
									</div>
								</div>
							)}
							<div className="row">
								<div className="col-12">
									<Link to="/">Tilbake</Link>
								</div>
							</div>
						</div>
					)}
				</div>
			</div>
			<Footer />
		</div>
	);
};

export default ScientistCornerPost;
export { ScientistCornerPost };
