import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faYoutube, faInstagram, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { useTranslation } from "react-i18next";
import "./Footer.scss";

const Footer = (): JSX.Element => {
	const { t } = useTranslation();

	return (
		<div className="container-fluid">
			<footer className="row mt-5 pt-3">
				<div className="col">
					<a href="https://www.hi.no" target="_blank" rel="noopener noreferrer">
						<img className="logo" src="/img/logo.png" alt="Logo" />
					</a>
				</div>
				<div className="col-11">
					<h3>{t("appName")}</h3>
					<ul className="list-unstyled">
						<li>
							<Link to="/om">{t("common:aboutTheProject")}</Link>
						</li>
						<li>
							<Link to="/kontakt">{t("common:contactUs")}</Link>
						</li>
					</ul>
					<a href="https://www.hi.no/hi/om-oss" target="_blank" rel="noopener noreferrer">
						{t("common:aboutIMR")}
					</a>
					<ul className="list-inline">
						<li className="list-inline-item">
							<a
								href="https://facebook.com/havforskningsinstituttet"
								target="_blank"
								rel="noopener noreferrer"
							>
								<FontAwesomeIcon icon={faFacebook} />
							</a>
						</li>
						<li className="list-inline-item">
							<a
								href="https://www.instagram.com/havforskningen"
								target="_blank"
								rel="noopener noreferrer"
							>
								<FontAwesomeIcon icon={faInstagram} />
							</a>
						</li>
						<li className="list-inline-item">
							<a
								href="https://www.youtube.com/user/havforskningen"
								target="_blank"
								rel="noopener noreferrer"
							>
								<FontAwesomeIcon icon={faYoutube} />
							</a>
						</li>
						<li className="list-inline-item">
							<a
								href="https://twitter.com/havforskningen"
								target="_blank"
								rel="noopener noreferrer"
							>
								<FontAwesomeIcon icon={faTwitter} />
							</a>
						</li>
					</ul>
				</div>
			</footer>
		</div>
	);
};

export default Footer;
export { Footer };
