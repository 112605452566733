import React, { useMemo, useState } from "react";
import { HavfunnDugnadMap } from "../common/map/HavfunnDugnadMap";
import { MapControls } from "../common/map/MapControls";
import moment from "moment";
import throttle from "lodash/throttle";
import "./Dataportal.scss";
import { useWindowSize } from "../../hooks/useWindowSize";
import * as R from "ramda";

const Dataportal = ({ navbar }: { navbar: React.RefObject<HTMLDivElement> }): JSX.Element => {
	// State for the controls
	const [format, setFormat] = useState<string>("csv");
	const [after, setAfter] = useState<Date>(moment().subtract(30, "days").toDate()); // Default to last 30 days
	const [before, setBefore] = useState<Date>(new Date()); // Default to today
	const [bounds, setBounds] = useState<{ n: number; e: number; s: number; w: number }>({
		n: 90,
		e: 180,
		s: -90,
		w: -180
	});
	const [selectedHavfunnCategories, setSelectedHavfunnCategoriesgories] = useState<string[]>([]);
	const [showHavfunn, setShowHavfunn] = useState<boolean>(true);
	const [selectedDugnadTypes, setSelectedDugnadTypes] = useState<string[]>([]);
	const [showDugnad, setShowDugnad] = useState<boolean>(true);
	const [charset, setCharset] = useState<string>("windows-1252");
	const [delimiter, setDelimiter] = useState<string>(";");
	const [recordDelimiter, setRecordDelimiter] = useState<string>("\r\n");
	const windowSize = useWindowSize();
	const [isFiltrationOpen, setIsFiltrationOpen] = useState(false);

	const getCssForSidebar = () => {
		if (!windowSize.sm) {
			return "sidebar";
		} else if (isFiltrationOpen && windowSize.sm) {
			return "small-open";
		} else {
			return "small-closed";
		}
	};

	const dataportalHeight = useMemo(() => {
		if (R.isNil(navbar.current)) {
			return windowSize.windowSize.height;
		}
		return windowSize.windowSize.height - navbar.current.clientHeight;
	}, [windowSize.windowSize, navbar]);

	return (
		<div className="dataportal-body" style={{ height: String(dataportalHeight) + "px" }}>
			<div className={`${getCssForSidebar()}`}>
				<MapControls
					options={{
						format,
						setFormat,
						charset,
						setCharset,
						delimiter,
						setDelimiter,
						recordDelimiter,
						setRecordDelimiter,
						after,
						before,
						setAfter,
						setBefore,
						bounds
					}}
					havfunn={{
						selectedCategories: selectedHavfunnCategories,
						setSelectedCategories: setSelectedHavfunnCategoriesgories,
						show: showHavfunn,
						setShow: setShowHavfunn
					}}
					dugnad={{
						selectedTypes: selectedDugnadTypes,
						setSelectedTypes: setSelectedDugnadTypes,
						show: showDugnad,
						setShow: setShowDugnad
					}}
					filtration={{ setIsFiltrationOpen }}
				/>
			</div>
			<div className="map">
				<HavfunnDugnadMap
					after={after}
					before={before}
					setBounds={throttle(setBounds, 500, { leading: false, trailing: true })}
					havfunn={{
						categories: selectedHavfunnCategories,
						show: showHavfunn
					}}
					dugnad={{ types: selectedDugnadTypes, show: showDugnad }}
					filtration={{
						isFiltrationOpen: isFiltrationOpen,
						setIsFiltrationOpen: setIsFiltrationOpen
					}}
				/>
			</div>
		</div>
	);
};

export default Dataportal;
export { Dataportal };
