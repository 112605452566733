import qs from "query-string";
import settings from "../../../settings";

interface FetchReportParams {
	format?: string;
	charset?: string;
	delimiter?: string;
	recordDelimiter?: string;
	top?: number;
	bottom?: number;
	left?: number;
	right?: number;
	after?: Date;
	before?: Date;
	validationStatus?: string;
	category?: string[];
	species?: string[];
}

/** Response objects from the reports API of Havfunn, simplified */
export interface Report {
	validSpecie: string | undefined | null;
	_id: string;
	latitude: number;
	longitude: number;
	category: string;
	species: string;
	date: string;
	isValidated: boolean;
}

/**
 * Makes an URL to the Havfunn API for fetching reports
 *
 * @param params	The parameters for the URL
 *
 * @returns	The URL to the API
 */
const makeReportAPIUrl = (params: FetchReportParams): string => {
	const args = {
		...params,
		after: params.after?.toISOString(),
		before: params.before?.toISOString()
	};

	return `${settings.HAVFUNN_URL}/api/reports?${qs.stringify(args)}`;
};

/**
 * Fetches reports from Havfunn through its API
 *
 * @param params	Params for the query
 *
 * @returns	The reports fetched from the API
 */
const fetchReports = async (params: FetchReportParams): Promise<Report[]> => {
	const newParams = { ...params, format: "json" };
	const url = makeReportAPIUrl(newParams);

	const response = await fetch(url);

	return response.json() as Promise<Report[]>;
};

/** Makes an URL to one specific report in Havfunn
 *
 * @param report	The report to make the URL to
 *
 * @returns	The URL to the report in Havfunn
 */
const makeReportUrl = (report: Report): string => `${settings.HAVFUNN_URL}/viewReport/${report._id}`;

export { fetchReports, makeReportUrl, makeReportAPIUrl };
