import L from "leaflet";

/** Map marker icons for Havfunn */
const havfunnIcons = new Proxy(
	{
		fishTag: L.icon({
			iconUrl: "/markers/havfunn/fishTag.png",
			iconRetinaUrl: "/markers/havfunn/fishTag-2x.png",
			shadowUrl: "/markers/havfunn/marker-shadow.png",
			iconAnchor: [12.5, 41],
			popupAnchor: [12.5, -41]
		}),
		algalGrowth: L.icon({
			iconUrl: "/markers/havfunn/coralSpecies.png",
			iconRetinaUrl: "/markers/havfunn/coralSpecies-2x.png",
			shadowUrl: "/markers/havfunn/marker-shadow.png",
			iconAnchor: [12.5, 41],
			popupAnchor: [12.5, -41]
		}),
		seaMammals: L.icon({
			iconUrl: "/markers/havfunn/seaMammals.png",
			iconRetinaUrl: "/markers/havfunn/seaMammals-2x.png",
			shadowUrl: "/markers/havfunn/marker-shadow.png",
			iconAnchor: [12.5, 41],
			popupAnchor: [12.5, -41]
		}),
		baskingShark: L.icon({
			iconUrl: "/markers/havfunn/baskingShark.png",
			iconRetinaUrl: "/markers/havfunn/baskingShark-2x.png",
			shadowUrl: "/markers/havfunn/marker-shadow.png",
			iconAnchor: [12.5, 41],
			popupAnchor: [12.5, -41]
		}),
		atlanticBluefinTuna: L.icon({
			iconUrl: "/markers/havfunn/atlanticBluefinTuna.png",
			iconRetinaUrl: "/markers/havfunn/atlanticBluefinTuna-2x.png",
			shadowUrl: "/markers/havfunn/marker-shadow.png",
			iconAnchor: [12.5, 41],
			popupAnchor: [12.5, -41]
		}),
		mackarel: L.icon({
			iconUrl: "/markers/havfunn/mackarel.png",
			iconRetinaUrl: "/markers/havfunn/mackarel-2x.png",
			shadowUrl: "/markers/havfunn/marker-shadow.png",
			iconAnchor: [12.5, 41],
			popupAnchor: [12.5, -41]
		}),
		coralSpecies: L.icon({
			iconUrl: "/markers/havfunn/algalGrowth.png",
			iconRetinaUrl: "/markers/havfunn/algalGrowth-2x.png",
			shadowUrl: "/markers/havfunn/marker-shadow.png",
			iconAnchor: [12.5, 41],
			popupAnchor: [12.5, -41]
		}),
		jellyfishSpecies: L.icon({
			iconUrl: "/markers/havfunn/jellyfishSpecies.png",
			iconRetinaUrl: "/markers/havfunn/jellyfishSpecies-2x.png",
			shadowUrl: "/markers/havfunn/marker-shadow.png",
			iconAnchor: [12.5, 41],
			popupAnchor: [12.5, -41]
		}),
		introducedSpecies: L.icon({
			iconUrl: "/markers/havfunn/introducedSpecies.png",
			iconRetinaUrl: "/markers/havfunn/introducedSpecies-2x.png",
			shadowUrl: "/markers/havfunn/marker-shadow.png",
			iconAnchor: [12.5, 41],
			popupAnchor: [12.5, -41]
		}),
		fishSpecies: L.icon({
			iconUrl: "/markers/havfunn/fishSpecies.png",
			iconRetinaUrl: "/markers/havfunn/fishSpecies-2x.png",
			shadowUrl: "/markers/havfunn/marker-shadow.png",
			iconAnchor: [12.5, 41],
			popupAnchor: [12.5, -41]
		}),
		otherSpecies: L.icon({
			iconUrl: "/markers/havfunn/otherSpecies.png",
			iconRetinaUrl: "/markers/havfunn/otherSpecies-2x.png",
			shadowUrl: "/markers/havfunn/marker-shadow.png",
			iconAnchor: [12.5, 41],
			popupAnchor: [12.5, -41]
		})
	} as { [propName: string]: L.Icon },
	{
		// Default to otherSpecies if the category is not found
		get: (target, prop: string) => {
			if (prop in target) {
				return target[prop];
			}
			return target.otherSpecies;
		}
	}
);

/** Icons for map markers for dugnad */
const dugnadIcons = new Proxy(
	{
		oyster: L.icon({
			iconUrl: "/markers/dugnad/oyster.png",
			iconRetinaUrl: "/markers/dugnad/oyster-2x.png",
			shadowUrl: "/markers/dugnad/marker-shadow.png",
			iconAnchor: [12.5, 41],
			popupAnchor: [12.5, -41]
		}),
		seaweed: L.icon({
			iconUrl: "/markers/dugnad/seaweed.png",
			iconRetinaUrl: "/markers/dugnad/seaweed-2x.png",
			shadowUrl: "/markers/dugnad/marker-shadow.png",
			iconAnchor: [12.5, 41],
			popupAnchor: [12.5, -41]
		}),
		litter: L.icon({
			iconUrl: "/markers/dugnad/litter.png",
			iconRetinaUrl: "/markers/dugnad/litter-2x.png",
			shadowUrl: "/markers/dugnad/marker-shadow.png",
			iconAnchor: [12.5, 41],
			popupAnchor: [12.5, -41]
		}),
		mussels: L.icon({
			iconUrl: "/markers/dugnad/mussels.png",
			iconRetinaUrl: "/markers/dugnad/mussels-2x.png",
			shadowUrl: "/markers/dugnad/marker-shadow.png",
			iconAnchor: [12.5, 41],
			popupAnchor: [12.5, -41]
		}),
		unknown: L.icon({
			iconUrl: "/markers/dugnad/unknown.png",
			iconRetinaUrl: "/markers/dugnad/unknown-2x.png",
			shadowUrl: "/markers/dugnad/marker-shadow.png",
			iconAnchor: [12.5, 41],
			popupAnchor: [12.5, -41]
		})
	} as { [propName: string]: L.Icon },
	{
		// Default to otherSpecies if the category is not found
		get: (target, prop: string) => {
			if (prop in target) {
				return target[prop];
			}
			return target.unknown;
		}
	}
);

export { havfunnIcons, dugnadIcons };
