import React from "react";
import { useHistory } from "react-router-dom";

const Todo = (): JSX.Element => {
	const history = useHistory();

	return (
		<div>
			<p>Denne siden finnes visst ikke enda.</p>
			<button type="button" className="btn btn-link" onClick={() => history.goBack()}>
				Tilbake
			</button>
		</div>
	);
};

export default Todo;
export { Todo };
