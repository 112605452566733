import React from "react";
import "./Header.scss";

const Header = ({ children }: { children: JSX.Element | JSX.Element[] | string }): JSX.Element => {
	return (
		<header className="text-center header">
			<h1>{children}</h1>
		</header>
	);
};

export default Header;
export { Header };
