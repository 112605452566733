import type { Report } from "./havfunnHelpers";
import type { ObservationSeries } from "./dugnadHelpers";
import React, { useEffect, useState, useRef } from "react";
import { Map as LMap, TileLayer } from "react-leaflet";
import { HavfunnLayer } from "./HavfunnLayer";
import { DugnadLayer } from "./DugnadLayer";
import * as havfunnHelpers from "./havfunnHelpers";
import * as dugnadHelpers from "./dugnadHelpers";
import * as R from "ramda";
import "leaflet/dist/leaflet.css";
import "./HavfunnDugnadMap.scss";
import L from "leaflet";
import { useTranslation } from "react-i18next";
import { useWindowSize } from "../../../hooks/useWindowSize";

/************************
 * The component itself *
 ************************/

/** The main map component for the application */
const HavfunnDugnadMap = ({
	after,
	before,
	setBounds,
	havfunn,
	dugnad,
	filtration
}: {
	after: Date;
	before: Date;
	setBounds: (a: { n: number; e: number; s: number; w: number }) => void;
	havfunn: { categories: string[]; show: boolean };
	dugnad: { types: string[]; show: boolean };
	filtration: {
		isFiltrationOpen: boolean;
		setIsFiltrationOpen: (value: boolean) => void;
	};
}): JSX.Element => {
	// Reference to the map
	const mapRef = useRef<LMap>(null);
	const { t } = useTranslation();
	const windowSize = useWindowSize();

	// Default to somewhat in the center of Norway
	const pos: [number, number] = [63.429, 10.393];
	const zoom = 4;

	// Get havfunn data
	const [havfunnReports, setHavfunnReports] = useState<Report[]>([]);
	useEffect(() => {
		if (!havfunn.show) {
			return;
		}

		void havfunnHelpers
			.fetchReports({
				after,
				before,
				category: havfunn.categories,
				validationStatus: "validatedOnly"
				// XXX Note that `bounds` should also be here, but it causes this effect
				// to trigger way too often, leading to way too many API-requests and
				// both havfunn and this crashing.
				// Instead, it will ignore the map bounds, leading to a bigger dataset than
				// necessary, but not leaving anything out, and not overloading anything
			})
			.then(setHavfunnReports);
	}, [after, before, havfunn.show, havfunn.categories]);

	// Get dugnad data
	const [dugnadObservationseries, setDugnadObservationSeries] = useState<ObservationSeries[]>([]);
	useEffect(() => {
		if (!dugnad.show) {
			return;
		}

		void dugnadHelpers
			.fetchObservationSeries({ after, before, types: dugnad.types.join(",") })
			.then(setDugnadObservationSeries);
	}, [after, before, dugnad.show, dugnad.types]);

	// Move to the user's location if allowed
	useEffect(() => {
		navigator.geolocation.getCurrentPosition(
			({ coords: { latitude, longitude } }) => {
				const map = mapRef.current?.leafletElement;

				if (R.isNil(map)) {
					return;
				}

				map.setView([latitude, longitude], 12, { animate: true, duration: 2 }); // XXX The animation doesn't appear to work for some reason
			},
			err => console.error("Could not get user's position:", err)
		);
	}, []);

	// Pass the map's bounds back to the parent component when they change
	useEffect(() => {
		const map = mapRef.current?.leafletElement;

		if (R.isNil(map)) {
			return;
		}

		const fn = () => {
			const b = map.getBounds();
			setBounds({
				n: b.getNorth(),
				e: b.getEast(),
				s: b.getSouth(),
				w: b.getWest()
			});
		};

		map.on("zoomend", fn);
		map.on("moveend", fn);

		return () => {
			map.removeEventListener("zoomend", fn);
			map.removeEventListener("moveend", fn);
		};
	});

	// Invalidate the map's size when the window is resized, avoiding gray areas at the bottom of the map
	const invalidateMapSize = () => mapRef.current?.leafletElement.invalidateSize();
	useEffect(() => {
		invalidateMapSize();
		window.addEventListener("resize", invalidateMapSize);
		return () => window.removeEventListener("resize", invalidateMapSize);
	}, []);

	useEffect(() => {
		if (mapRef && mapRef.current) {
			const map = mapRef.current.leafletElement;

			map.zoomControl.remove();

			L.control
				.zoom({
					position: "topright"
				})
				.addTo(map);
		}
	}, []);

	return (
		<div className="havfunn-dugnad-map-container" style={{ height: "100%" }}>
			<LMap ref={mapRef} center={pos} zoom={zoom} style={{ height: "100%" }}>
				{!filtration.isFiltrationOpen && windowSize.sm ? (
					<button
						type="button"
						className="filter-button"
						onClick={() => filtration.setIsFiltrationOpen(true)}
					>
						{t("filtration:filterObservations")}
					</button>
				) : null}
				<TileLayer
					url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
					attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
				/>

				{havfunn.show ? <HavfunnLayer reports={havfunnReports} /> : null}
				{dugnad.show ? <DugnadLayer observationSeries={dugnadObservationseries} /> : null}
			</LMap>
		</div>
	);
};

export default HavfunnDugnadMap;
export { HavfunnDugnadMap };
