import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import settings from "../../settings";
import { useTranslation } from "react-i18next";
import "./ScientistCorner.scss";

interface PostIngress {
	_id: string;
	createdAt: Date;
	ingress: string;
}

type RawPostIngress = Omit<PostIngress, "createdAt"> & { createdAt: string };

const ScientistCorner = (): JSX.Element => {
	const { t } = useTranslation();

	const [posts, setPosts] = useState<PostIngress[]>([]);
	useEffect(() => {
		const fn = () =>
			void fetch(`${settings.ADMIN_URL}/api/scientistCorner/ingressOfLastN?n=5`)
				.then(res => res.json() as Promise<RawPostIngress[]>)
				.then(res =>
					res.map(post => ({
						...post,
						createdAt: new Date(post.createdAt)
					}))
				)
				.then(setPosts)
				.catch(err => console.error(err));

		fn();
	}, []);

	return (
		<div className="scientist-corner">
			<h2>{t("scientistCorner:scientistCorner")}</h2>
			<ul className="list-unstyled">
				{posts.map(({ _id, ingress }) => (
					<li key={_id} className="pt-2 mb-2">
						<Link to={`/forskerhjørnet/${_id}`}>{ingress}</Link>
					</li>
				))}
			</ul>
			<Link to="/forskerhjørnet">
				{t("scientistCorner:seeMore")} <FontAwesomeIcon icon={faAngleDown} />
			</Link>
		</div>
	);
};
export default ScientistCorner;
export { ScientistCorner };
