import type { Polygon, Feature } from "geojson";
import settings from "../../../settings";
import qs from "query-string";

/** Parameters for fetching observation series through Dugnad's API */
export interface FetchObservationSeriesParams {
	after?: Date;
	before?: Date;
	top?: number;
	bottom?: number;
	left?: number;
	right?: number;
	format?: string;
	charset?: string;
	delimiter?: string;
	recordDelimiter?: string;
	types?: string;
}

/** Response objects from the reports API of Dugnad */
export interface ObservationSeries {
	_id: string;
	areaCoordinates: Feature<Polygon>;
	observationType: string;
	name: string;
	startDate: string;
	observationIds: string[];
}

const makeObservationSeriesUrl = (s: ObservationSeries): string =>
	// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
	`${settings.TIDSSERIER_URL}/api/observationSeries/one?${qs.stringify({ id: s._id })}`;

/**
 * Makes an URL for fetching observation series from Dugnad's API
 *
 * @param params	The parameters to use for the URL
 *
 * @returns	The URL to the API
 */
const makeObservationSeriesAPIUrl = (params: FetchObservationSeriesParams): string => {
	const args = {
		...params,
		after: params.after?.toISOString(),
		before: params.before?.toISOString()
	};

	return `${settings.TIDSSERIER_URL}/api/observationSeries/all?${qs.stringify(args)}`;
};

/**
 * Fetches all observation series
 *
 * @returns	Promise resolving to the observation series
 */
const fetchObservationSeries = async (params: FetchObservationSeriesParams): Promise<ObservationSeries[]> => {
	const newParams = { ...params, format: "json" };
	const url = makeObservationSeriesAPIUrl(newParams);

	const response = await fetch(url);

	return response.json() as Promise<ObservationSeries[]>;
};

export { makeObservationSeriesAPIUrl, makeObservationSeriesUrl, fetchObservationSeries };
