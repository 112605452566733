import React from "react";
import { Header } from "../common/Header";
import { Sidebar } from "../common/Sidebar";
import { Footer } from "../common/Footer";
import { useTranslation } from "react-i18next";
import "./CitizenScientist.scss";

const CitizenScientist = (): JSX.Element => {
	const { t } = useTranslation();

	return (
		<div className="citizen-scientist">
			<Header>{t("citizenScientist:header")}</Header>
			<div className="container">
				<div className="row">
					<div className="col-12 col-md-3">
						<Sidebar>
							<ul className="greenblue-thing">
								<li>{t("citizenScientist:fact1")}</li>
								<li>{t("citizenScientist:fact2")}</li>
								<li>{t("citizenScientist:fact3")}</li>
							</ul>
							<img
								className="img-fluid"
								src="/img/sidebar/mussels.jpg"
								alt=""
							/>
							<img className="img-fluid" src="/img/sidebar/kelp.jpg" alt="" />
						</Sidebar>
					</div>
					<div className="col-12 col-md-9 text-md-justify">
						<div className="row">
							<div className="col-12">
								<p>
									<strong>
										{t("citizenScientist:headerText")}
									</strong>
								</p>
							</div>
						</div>
						<div className="row">
							<div className="col-12">
								<h2 className="header-blue">
									{t("citizenScientist:feedback")}
								</h2>
								<p>{t("citizenScientist:feedbackp1")}</p>
								<p>{t("citizenScientist:feedbackp2")}</p>
							</div>
						</div>
						<div className="row">
							<div className="col-12">
								<h2 className="header-blue">
									{t("citizenScientist:selectedSpecies")}
								</h2>
								<p>{t("citizenScientist:selectedSpeciesp1")}</p>
								<p>{t("citizenScientist:selectedSpeciesp2")}</p>
								<p>{t("citizenScientist:selectedSpeciesp3")}</p>
							</div>
						</div>
						<div className="row">
							<div className="col-12">
								<h2 className="header-blue">
									{t("citizenScientist:possibleRegistrations")}
								</h2>
								<p>{t("citizenScientist:possibleRegistrationsp1")}</p>
								<p>{t("citizenScientist:possibleRegistrationsp2")}</p>
								<p>
									<i>
										{t(
											"citizenScientist:possibleRegistrationsp3"
										)}
									</i>
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
};

export default CitizenScientist;
export { CitizenScientist };
