import type { ObservationSeries } from "./dugnadHelpers";
import type { GeolibInputCoordinates } from "geolib/es/types";
import React, { useState, useEffect } from "react";
import { LayerGroup, GeoJSON, Popup, Marker, useLeaflet } from "react-leaflet";
import { useTranslation } from "react-i18next";
import moment from "moment";
import L from "leaflet";
import * as geolib from "geolib";
import * as helpers from "./dugnadHelpers";
import { dugnadIcons as icons } from "./icons";

/*************************
 * Constants and helpers *
 *************************/

/** Zoom level at which the markers get hidden */
const hideMarkerZoomLevel = 15;

/** Colors for the polygons */
const colors = new Proxy(
	{
		mussels: "#9999FF",
		oyster: "#EAEAEA",
		litter: "#888888",
		seaweed: "#00AA00",
		unknown: "#AA0000"
	} as { [propName: string]: string },
	{
		// Default to otherSpecies if the category is not found
		get: (target, prop: string) => {
			if (prop in target) {
				return target[prop];
			}
			console.log(`Unknown observation type ${prop}`);
			return target.unknown;
		}
	}
);

/************************
 * The component itself *
 ************************/

const DugnadLayer = ({ observationSeries }: { observationSeries: ObservationSeries[] }): JSX.Element => {
	// Get translator
	const [t] = useTranslation();

	// Remove the markers when the zoom level is high enough. XXX Need to have this as an event because the component does not rerender when zooming
	const { map } = useLeaflet();
	const [zoom, setZoom] = useState(map?.getZoom() ?? 1);
	useEffect(() => {
		const fn = () => {
			setZoom(map?.getZoom() ?? 1);
		};
		void map?.on("zoom", fn);

		return () => {
			void map?.removeEventListener("zoom", fn);
		};
	}, [map]);

	return (
		<LayerGroup>
			{observationSeries.map(s => {
				const center = geolib.getCenterOfBounds(
					s.areaCoordinates.geometry.coordinates[0] as GeolibInputCoordinates[]
				);
				const centerLatLng = L.latLng(center.latitude, center.longitude);
				const url = helpers.makeObservationSeriesUrl(s);

				const makePopup = (showZoomButton: boolean) => (
					<Popup>
						<dl>
							<dt>{t("dugnad:observationType")}</dt>
							<dd>{t(`dugnadObservationTypes:${s.observationType}`)}</dd>
							<dt>{t("dugnad:observationSeriesName")}</dt>
							<dd>{s.name}</dd>
							<dt>{t("dugnad:startDate")}</dt>
							<dd>{moment(s.startDate).format("YYYY-MM-DD")}</dd>
							<dt>{t("dugnad:noOfObservations")}</dt>
							<dd>{s.observationIds.length}</dd>
							{showZoomButton ? (
								<React.Fragment>
									<dt></dt>
									<dd>
										<button
											type="button"
											className="btn btn-primary"
											onClick={() =>
												map?.flyTo(
													centerLatLng,
													hideMarkerZoomLevel,
													{
														animate: true,
														duration: 1
													}
												)
											}
										>
											Zoom
										</button>
									</dd>
								</React.Fragment>
							) : (
								<React.Fragment>
									<dt>{t("dugnad:downloadData")}</dt>
									<dd>
										<a
											href={url}
											target="_blank"
											rel="noopener noreferrer"
										>
											{url}
										</a>
									</dd>
								</React.Fragment>
							)}
						</dl>
					</Popup>
				);

				return (
					<GeoJSON
						key={s._id}
						style={{ color: colors[s.observationType] }}
						data={s.areaCoordinates}
					>
						{zoom < hideMarkerZoomLevel ? (
							<Marker position={centerLatLng} icon={icons[s.observationType]}>
								{makePopup(true)}
							</Marker>
						) : null}
						{makePopup(false)}
					</GeoJSON>
				);
			})}
		</LayerGroup>
	);
};

export default DugnadLayer;
export { DugnadLayer };
