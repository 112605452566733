import React, { useState, useEffect } from "react";
import { Header } from "../common/Header";
import { Sidebar } from "../common/Sidebar";
import { Footer } from "../common/Footer";
import { Link } from "react-router-dom";
import settings from "../../settings";
import { formatDate } from "../../helpers/formatDate";
import { useTranslation } from "react-i18next";
import "./ScientistCornerIndex.scss";

interface PostIngress {
	_id: string;
	ingress: string;
	createdAt: Date;
}

type RawPostIngress = Omit<PostIngress, "createdAt"> & { createdAt: string };

const ScientistCornerIndex = (): JSX.Element => {
	const { t } = useTranslation();

	const [posts, setPosts] = useState<PostIngress[]>([]);
	useEffect(() => {
		const fn = () =>
			void fetch(`${settings.ADMIN_URL}/api/scientistCorner/ingressOfLastN?n=100`)
				.then(res => res.json() as Promise<RawPostIngress[]>)
				.then(res =>
					res.map(post => ({
						...post,
						createdAt: new Date(post.createdAt)
					}))
				)
				.then(setPosts)
				.catch(err => {
					console.error(err);
				});

		fn();
	}, []);

	return (
		<div>
			<Header>{t("scientistCorner:scientistCorner")}</Header>
			<div className="container scientist-corner-index">
				<div className="row">
					<div className="col-12 col-md-3">
						<Sidebar>
							<img
								className="img-fluid"
								src="/img/sidebar/mussels.jpg"
								alt=""
							/>
							<img className="img-fluid" src="/img/sidebar/kelp.jpg" alt="" />
						</Sidebar>
					</div>
					<div className="col-12 col-md-9 text-md-justify">
						<div className="row">
							<div className="col-12">
								<ul className="list-unstyled">
									{posts.map(post => (
										<li key={post._id}>
											<Link
												to={`/forskerhjørnet/${post._id}`}
											>
												<h2>{post.ingress}</h2>
												<time
													dateTime={post.createdAt.toISOString()}
												>
													{formatDate(
														post.createdAt
													)}
												</time>
											</Link>
										</li>
									))}
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
};

export default ScientistCornerIndex;
export { ScientistCornerIndex };
