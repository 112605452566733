import React from "react";
import Router from "./router/Router";
import { I18nextProvider } from "react-i18next";
import { i18next } from "./i18n";

const App = (): JSX.Element => (
	<React.StrictMode>
		<I18nextProvider i18n={i18next}>
			<Router />
		</I18nextProvider>
	</React.StrictMode>
);

export default App;
