import * as yup from "yup";

/*****************************************************************************
 * This model descibes the structure of the environment variables object the *
 * application is using.                                                     *
 *****************************************************************************/

export interface Settings {
	REPORT_SERVICE_URL: string;
	NODE_ENV: "development" | "test" | "production";
	HAVFUNN_URL: string;
	TIDSSERIER_URL: string;
	EXCURSION_URL: string;
	ACCOUNTS_URL: string;
	ADMIN_URL: string;
	GA_TOKEN: string;
}

/** Schema describing the settings object */
export const settingsSchema = yup.object({
	NODE_ENV: yup.mixed().oneOf(["development", "test", "production"]).required(),
	HAVFUNN_URL: yup.string().required(),
	TIDSSERIER_URL: yup.string().required(),
	EXCURSION_URL: yup.string().required(),
	ACCOUNTS_URL: yup.string().required(),
	ADMIN_URL: yup.string().required(),
	GA_TOKEN: yup.string().required(),
	REPORT_SERVICE_URL: yup.string().required()
});

// Make sure the schema actually describes the type. This line will cause compilation errors if they do not match
settingsSchema as yup.SchemaOf<Settings>;
